import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import ExamplePageTwo from '../ExampleTwo/index';
import { isAuthenticated } from '../../services/auth';
import Home from '../Home';
import Register from '../Register';
import Profile from '../Profile';
import Invoice from '../Invoice/index';
import Operation from '../Operation/index';
import Shoppings from '../Shoppings';
import Pricing from '../Pricing/index';
import CheckoutConfirmation from '../CheckoutConfirmation';
import Control from '../Control';
import Documentation from '../Documentation';
import ForgetPassword from '../ForgetPassword';
import RecoverPassword from '../RecoverPassword';
import Recharge from '../Recharge';
import PIXPage from '../Recharge/components/Pix';
import PaymentInformation from '../PaymentInformation';
import HistoricPayments from '../HistoricPayments';
import HistoricConsumption from '../HistoricConsumption';

const Pages = () => (
  <Switch>
    <Route path="/pages/two" component={ExamplePageTwo} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/pages" component={Pages} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/pricing" component={Pricing} />
        <Route
          exact
          path="/checkoutConfirmation"
          component={CheckoutConfirmation}
        />
        <Route exact path="/historic-payments" component={HistoricPayments} />
        <Route
          exact
          path="/historic-consumption"
          component={HistoricConsumption}
        />
        <Route exact path="/invoice" component={Invoice} />
        <Route exact path="/control" component={Control} />
        <Route exact path="/operation" component={Operation} />
        <Route exact path="/recharge" component={Recharge} />
        <Route
          exact
          path="/paymentInformation"
          component={PaymentInformation}
        />
        <Route exact path="/pix" component={PIXPage} />
        <Route exact path="/shopping" component={Shoppings} />
        <Route exact path="/documentation" component={Documentation} />
        <Route path="*" component={Home} />
      </Switch>
    </div>
  </div>
);

const Router = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route path="/login" component={LogIn} />
          <Route path="/register" component={Register} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/recoverPassword" component={RecoverPassword} />
          <PrivateRoute path="/" component={wrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  location: PropTypes.oneOfType([PropTypes.any])
};

PrivateRoute.defaultProps = {
  location: ''
};

export default Router;
