/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import AtIcon from 'mdi-react/AtIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import PuffLoader from 'react-spinners/PuffLoader';
import { login } from '../../../services/auth';
import Modal from '../../../shared/Modal';

const LogInForm = ({ history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const apiUrl = `${process.env.REACT_APP_WAS_KEY}/getAccountToken`;
  const apiUrlConfig = `${process.env.REACT_APP_WAS_KEY}/clientConfig`;
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [color] = useState('#00AFEF');
  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(apiUrl, data);
      if (res.data?.token) {
        const response = await axios.get(apiUrlConfig, {
          headers: { Authorization: `Bearer ${res.data.token}` }
        });
        localStorage.setItem('login', JSON.stringify(data));
        localStorage.setItem('client', JSON.stringify(response.data.client));
        localStorage.setItem(
          'config',
          JSON.stringify(response.data.config) || null
        );
        login(res.data.token);
        history.push('/home');
      } else {
        throw res.data;
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err;
      setError(errorMessage);
      setLoading(false);
      setOpenError(true);
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem('login');
    if (loggedInUser) {
      onSubmit(JSON.parse(loggedInUser));
    }
  });

  return loading ? (
    <div className="sweet-loading">
      <PuffLoader
        color={color}
        loading={loading}
        size={100}
        speedMultiplier={1.5}
      />
    </div>
  ) : (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Login</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <input
              name="name"
              type="text"
              placeholder=""
              {...register('email', {
                required: 'Este campo é obrigatório.',
                pattern: {
                  value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: 'Insira um email válido.'
                }
              })}
            />
          </div>
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Senha</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={isPasswordShown ? 'text' : 'password'}
              placeholder=""
              {...register('password', {
                required: 'Este campo é obrigatório.'
              })}
            />
            <button
              className={`form__form-group-button${
                isPasswordShown ? ' active' : ''
              }`}
              onClick={() => handleShowPassword()}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
          <div className="hr" />
          <div className="account__create-account">
            <a href="/register">Cadastre-se</a>
          </div>
          <div className="account__forgot-password">
            <a href="/forgetPassword">Esqueceu a sua senha?</a>
          </div>
        </div>
        <button
          className="btn btn-primary account__btn account__btn--small"
          type="submit"
        >
          Entrar
        </button>
      </form>
      {openError && (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message={error}
          isOpened={openError}
        />
      )}
    </>
  );
};

LogInForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default withRouter(LogInForm);
