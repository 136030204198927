/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';
import { getToken } from '../../services/auth';

const paymentOptions = [
  {
    id: 1,
    name: 'Pix',
    description:
      'Realize sua recarga de forma instantânea utilizando o Pix. Aproveite a rapidez e segurança desse meio de pagamento para adicionar saldo à sua conta.',
    imgSrc: `${process.env.PUBLIC_URL}/img/pix.png`
  }
  //   { id: 2, name: 'Cartão de credito', description: 'Realize sua recarga de forma imediata utilizando seu cartão de crédito. Aproveite a conveniência e segurança desse método de pagamento para adicionar saldo rapidamente à sua conta.', imgSrc: `${process.env.PUBLIC_URL}/img/pix.png` }
];

const Recharge = () => {
  const { owner } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';
  const { handleSubmit } = useForm();
  const [color] = useState('#00AFEF');
  const [selectedOption, setSelectedOption] = useState(null);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleSelect = (id) => {
    setSelectedOption(id);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setAmount(parseFloat(location.state));
  }, [location.state]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/wallet/pix/qrcode`;
      const response = await axios.post(
        apiUrl,
        { amount },
        {
          headers: { Authorization: `Bearer ${getToken()}`, 'x-api-key': owner }
        }
      );
      const { emv, transactionId } = response.data;
      await history.push('/pix', { emv, transactionId });
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="dashboard">
      <Row style={{ marginBottom: '10px' }}>
        <Col xs={12} md={12} xl={10}>
          <h3 className="page-title" style={{ textTransform: 'none' }}>
            Meios de pagamento
          </h3>
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Col
                    xl={12}
                    className="d-flex text-center align-items-center justify-content-center my-4"
                  >
                    <div className="sweet-loading">
                      <PuffLoader
                        color={color}
                        loading={loading}
                        size={100}
                        speedMultiplier={1.5}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={12} lg={12} xl={12}>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Row className="w-100">
                        <Col md={12} lg={12} xl={12}>
                          <div
                            className="form__form-group"
                            style={{ marginBottom: '0px' }}
                          >
                            <span className="payment_info-title-card-2">
                              Selecione o meio de pagamento
                            </span>
                          </div>
                          <div className="payment-options mt-2">
                            {paymentOptions.map((option) => (
                              <div
                                key={option.id}
                                className={`payment-card mb-3 ${
                                  selectedOption === option.id ? 'selected' : ''
                                }`}
                                onClick={() => handleSelect(option.id)}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={option.imgSrc}
                                    alt={option.name}
                                    className="payment-icon"
                                  />
                                  <div className="ml-3">
                                    <h5 className="card-title">
                                      {option.name}
                                    </h5>
                                    <p className="card-text">
                                      {option.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <button
                            type="submit"
                            disabled={!selectedOption}
                            className="btn btn-primary mt-3"
                          >
                            Prosseguir
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Recharge;
