/* eslint-disable */
import React, { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import RenameBoxIcon from 'mdi-react/RenameBoxIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import AtIcon from 'mdi-react/AtIcon';
import { Col, Row } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PuffLoader from 'react-spinners/PuffLoader';
import cep from 'cep-promise';
import MaskedInput from 'react-text-mask';
import axios from 'axios';
// import ReCAPTCHA from 'react-google-recaptcha';
import Modal from '../../../shared/Modal';

const RegisterForm = (props) => {
  // const recaptchaRef = React.createRef();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [color] = useState('#00AFEF');
  const history = useHistory();
  const watchShowType = watch('type');

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'zipCode' && value.zipCode.search('_') === -1) {
        cep(value.zipCode.replace('-', '')).then((result) => {
          setValue('street', result.street, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue('neighborhood', result.neighborhood, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue('city', result.city, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue('state', result.state, {
            shouldValidate: true,
            shouldDirty: true
          });
        });
      }
      if (name === 'cnpj' && value.cnpj.search('_') === -1) {
        const cnpj = value.cnpj
          .replace('.', '')
          .replace('.', '')
          .replace('/', '')
          .replace('-', '');
        axios
          .get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
          .then((res) => {
            setValue('name', res.nome);
            setValue('street', res.logradouro);
            setValue('neighborhood', res.bairro);
            setValue('city', res.municipio);
            setValue('state', res.uf);
          });
      }
      // if (name === 'cpf' && value.cpf.search('_') === -1) {
      //   recaptchaRef.current.reset();
      //   setTimeout(() => {
      //     recaptchaRef.current.execute();
      //   }, 1000);
      // }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const onSubmit = (data) => {
    setLoading(true);
    props.onSubmit(true);
    const dataForm = data;
    if (dataForm.type === 'Pysical') {
      dataForm.doc = dataForm.cpf
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
    } else {
      dataForm.doc = dataForm.cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
    }
    delete dataForm.cpf;
    delete dataForm.cnpj;
    delete dataForm.type;
    dataForm.address = {
      zipCode: data.zipCode,
      street: data.street,
      streetNumber: data.streetNumber,
      complement: data.complement,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state
    };
    delete dataForm.street;
    delete dataForm.streetNumber;
    delete dataForm.complement;
    delete dataForm.streetNumber;
    delete dataForm.neighborhood;
    delete dataForm.city;
    delete dataForm.state;
    delete dataForm.zipCode;
    const apiUrl = `${process.env.REACT_APP_WAS_KEY}/registerAccount`;
    axios
      .post(apiUrl, dataForm)
      .then((res) => {
        if (res.data.created === false) throw new Error('Erro Interno');
        setOpen(true);
        setTimeout(() => {
          history.push('/login');
        }, 6000);
      })
      .catch(() => {
        setOpenError(true);
        setLoading(false);
        props.onSubmit(false);
      });
  };

  return loading ? (
    <div className="sweet-loading">
      <PuffLoader
        color={color}
        loading={loading}
        size={100}
        speedMultiplier={1.5}
      />
      {open ? (
        <Modal
          color="primary"
          title="Sucesso!"
          colored
          message="Os seus dados foram cadastrados, agora você pode aproveitar os serviços do WinnGlobal."
          isOpened={open}
        />
      ) : null}
    </div>
  ) : (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Row className="account-row">
          <Col md={4} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Tipo de Pessoa</span>
              <div className="form__form-group-field">
                <select
                  {...register('type', { required: true })}
                  className="select-form"
                >
                  <option value="Pysical">Fisica</option>
                  <option value="Juridical">Júridica</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
        {watchShowType === 'Pysical' ? (
          <Row className="account-row">
            <Col md={12} className="account-col">
              <div className="form__form-group">
                <span className="form__form-group-label">CPF</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Controller
                    name="cpf"
                    rules={{ required: 'Este campo é obrigatório.' }}
                    control={control}
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/
                        ]}
                        name="cpf"
                        type="text"
                        placeholder=""
                      />
                    )}
                  />
                </div>
                {errors.cpf && (
                  <span className="error">{errors.cpf.message}</span>
                )}
                {/* <div>
                  <ReCAPTCHA
                    sitekey="6LczSpccAAAAAPji7i5FTRbezDx9-kL_Rg-qgBdF"
                    ref={recaptchaRef}
                    size="invisible"
                  />
                </div> */}
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="account-row">
            <Col md={12} className="account-col">
              <div className="form__form-group">
                <span className="form__form-group-label">CNPJ</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <DomainIcon />
                  </div>
                  <Controller
                    name="cnpj"
                    rules={{ required: 'Este campo é obrigatório.' }}
                    control={control}
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={[
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/
                        ]}
                        name="cnpj"
                        type="text"
                        placeholder=""
                      />
                    )}
                  />
                </div>
                {errors.cnpj && (
                  <span className="error">{errors.cnpj.message}</span>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">{watchShowType === 'Pysical' ? 'Nome' : 'Razão Social'}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <RenameBoxIcon />
            </div>
            <input
              name="name"
              type="text"
              placeholder=""
              {...register('name', {
                required: 'Este campo é obrigatório.'
              })}
            />
          </div>
          {errors.name && <span className="error">{errors.name.message}</span>}
        </div>
        <Row className="account-row">
          <Col md={4} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">CEP</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <Controller
                  name="zipCode"
                  rules={{ required: 'Este campo é obrigatório.' }}
                  control={control}
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                      name="zipCode"
                      type="text"
                      placeholder=""
                    />
                  )}
                />
              </div>
              {errors.zipCode && (
                <span className="error">{errors.zipCode.message}</span>
              )}
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Logradouro</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="street"
                  type="text"
                  placeholder=""
                  {...register('street', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
              {errors.street && (
                <span className="error">{errors.street.message}</span>
              )}
            </div>
          </Col>
          <Col md={2} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Número</span>
              <div className="form__form-group-field">
                <input
                  name="streetNumber"
                  type="text"
                  placeholder=""
                  {...register('streetNumber', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
              {errors.streetNumber && (
                <span className="error">{errors.streetNumber.message}</span>
              )}
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Complemento</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="complement"
                  type="text"
                  placeholder=""
                  {...register('complement')}
                />
              </div>
              {errors.complement && (
                <span className="error">{errors.complement.message}</span>
              )}
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Bairro</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="neighborhood"
                  type="text"
                  placeholder=""
                  {...register('neighborhood', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
              {errors.neighborhood && (
                <span className="error">{errors.neighborhood.message}</span>
              )}
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Cidade</span>
              <div
                className="form__form-group-field"
                style={{ paddingRight: '16px' }}
              >
                <input
                  name="city"
                  type="text"
                  placeholder=""
                  {...register('city', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
              {errors.city && (
                <span className="error">{errors.city.message}</span>
              )}
            </div>
          </Col>
          <Col md={6} className="account-col">
            <div className="form__form-group">
              <span className="form__form-group-label">Estado</span>
              <div className="form__form-group-field">
                <input
                  name="state"
                  type="text"
                  placeholder=""
                  {...register('state', {
                    required: 'Este campo é obrigatório.'
                  })}
                />
              </div>
              {errors.state && (
                <span className="error">{errors.state.message}</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <input
              name="email"
              type="text"
              placeholder=""
              {...register('email', {
                required: 'Este campo é obrigatório.',
                pattern: {
                  value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: 'Insira um email válido.'
                }
              })}
            />
          </div>
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Senha</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={isPasswordShown ? 'text' : 'password'}
              placeholder=""
              {...register('password', {
                required: 'Este campo é obrigatório.'
              })}
            />
            <button
              className={`form__form-group-button${
                isPasswordShown ? ' active' : ''
              }`}
              onClick={() => handleShowPassword()}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
        </div>
        <button
          className="btn btn-primary account__btn account__btn--small"
          type="submit"
        >
          Cadastrar
        </button>
        <span
          className="form__form-group-description"
          style={{ fontWeight: 600 }}
        >
          <a href="/">Voltar para o Login</a>
        </span>
      </form>
      {openError ? (
        <Modal
          color="danger"
          title="Erro!"
          colored
          message="Ocorreu um erro interno, por favor aguarde um momento ou tente novamente mais tarde."
          isOpened={openError}
        />
      ) : null}
    </>
  );
};

RegisterForm.propTypes = {
  history: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default withRouter(RegisterForm);
